import React from 'react';
import Layout from '../components/layout';
import PrivacyPolicy from '../components/privacy-policy/PrivacyPolicy';

const Privacy = () => {
  return (
    <Layout pageTitle='Privacy Page'>
      <PrivacyPolicy />
    </Layout>
  );
};

export default Privacy;
